import {
  Box,
  Button,
  CircularProgress,
  Snackbar,
  TextField,
} from "@mui/material";
import MuiAlert from "@mui/material/Alert";
import useMediaQuery from "@mui/material/useMediaQuery";
import { Formik } from "formik";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import * as yup from "yup";
import { createContactUs } from "../../../apiConfig/ApiConfig.jsx";
import Header from "../../../components/Header";

const AddContact = () => {
  const isNonMobile = useMediaQuery("(min-width:600px)");
  const [loading, setLoading] = useState(false);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [alertSeverity, setAlertSeverity] = useState("success");
  const navigate = useNavigate();

  const handleFormSubmit = async (values) => {
    setLoading(true);
    try {
      const response = await createContactUs(values);
      if (response.status) {
        setAlertMessage("Contact details added successfully!");
        setAlertSeverity("success");
        setOpenSnackbar(true);
        setTimeout(() => {
          navigate("/contact");
        }, 2500);
      } else {
        setAlertMessage("Failed to add contact details.");
        setAlertSeverity("error");
        setOpenSnackbar(true);
      }
    } catch (error) {
      console.error("Failed to add contact details:", error);
      setAlertMessage("An error occurred while adding contact details.");
      setAlertSeverity("error");
      setOpenSnackbar(true);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Box m="20px" position="relative">
      <Header title="ADD CONTACT DETAILS" subtitle="Add New Contact Details" />

      {loading && (
        <CircularProgress
          size={24}
          sx={{
            position: "absolute",
            left: "50%",
            top: "50%",
            marginLeft: "-12px",
            marginTop: "-12px",
          }}
        />
      )}

      <Formik
        onSubmit={handleFormSubmit}
        initialValues={{
          location: "",
          email: "",
          phoneNumber: "",
        }}
        validationSchema={checkoutSchema}
      >
        {({
          values,
          errors,
          touched,
          handleBlur,
          handleChange,
          handleSubmit,
        }) => (
          <form onSubmit={handleSubmit}>
            <Box
              display="grid"
              gap="30px"
              gridTemplateColumns="repeat(4, minmax(0, 1fr))"
              sx={{
                "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
              }}
            >
              <TextField
                fullWidth
                variant="filled"
                type="text"
                label="Location"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.location}
                name="location"
                error={!!touched.location && !!errors.location}
                helperText={touched.location && errors.location}
                sx={{ gridColumn: "span 4" }}
              />
              <TextField
                fullWidth
                variant="filled"
                type="text"
                label="Email"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.email}
                name="email"
                error={!!touched.email && !!errors.email}
                helperText={touched.email && errors.email}
                sx={{ gridColumn: "span 4" }}
              />
              <TextField
                fullWidth
                variant="filled"
                type="text"
                label="Phone Number"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.phoneNumber}
                name="phoneNumber"
                error={!!touched.phoneNumber && !!errors.phoneNumber}
                helperText={touched.phoneNumber && errors.phoneNumber}
                sx={{ gridColumn: "span 4" }}
              />
            </Box>
            <Box display="flex" justifyContent="end" mt="20px">
              <Button
                type="submit"
                color="secondary"
                variant="contained"
                disabled={loading}
                sx={{
                  bgcolor: loading ? "grey" : "secondary.main",
                  "&:hover": { bgcolor: loading ? "grey" : "secondary.dark" },
                  position: "relative",
                }}
              >
                {loading && (
                  <CircularProgress
                    size={24}
                    sx={{
                      position: "absolute",
                      left: "50%",
                      top: "50%",
                      marginLeft: "-12px",
                      marginTop: "-12px",
                    }}
                  />
                )}
                Add Contact Details
              </Button>
            </Box>
          </form>
        )}
      </Formik>

      <Snackbar
        open={openSnackbar}
        autoHideDuration={5000}
        onClose={() => setOpenSnackbar(false)}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <MuiAlert
          onClose={() => setOpenSnackbar(false)}
          severity={alertSeverity}
          elevation={6}
          variant="filled"
          sx={{ color: "#fff" }}
        >
          {alertMessage}
        </MuiAlert>
      </Snackbar>
    </Box>
  );
};

const checkoutSchema = yup.object().shape({
  location: yup.string().required("Location is required"),
  email: yup.string().email("Invalid email").required("Email is required"),
  phoneNumber: yup.string().required("Phone Number is required"),
});

export default AddContact;
