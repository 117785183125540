import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import VisibilityIcon from "@mui/icons-material/Visibility";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Card,
  CardContent,
  CardMedia,
  Grid,
  IconButton,
  Tooltip,
  Typography,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";

import {
  deleteCard,
  getAllCards,
  getCompanyDetails,
  getFrontDetails,
} from "../../../apiConfig/ApiConfig.jsx";
import Header from "../../../components/Header";
import { tokens } from "../../../theme";

const HOME = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const navigate = useNavigate();

  const [companyDetails, setCompanyDetails] = useState(null);
  const [frontDetails, setFrontDetails] = useState(null);
  const [cards, setCards] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchCompanyDetails = async () => {
      try {
        const response = await getCompanyDetails(1);
        if (response) {
          setCompanyDetails(response);
        } else {
          setCompanyDetails(null);
        }
      } catch (error) {
        setCompanyDetails(null);
      } finally {
        setIsLoading(false);
      }
    };

    fetchCompanyDetails();
  }, []);

  useEffect(() => {
    const fetchFrontDetails = async () => {
      try {
        const response = await getFrontDetails(1);
        setFrontDetails(response);
      } catch (error) {
        setFrontDetails(null);
      }
    };

    fetchFrontDetails();
  }, []);

  useEffect(() => {
    const fetchCards = async () => {
      try {
        const cardData = await getAllCards();
        setCards(cardData);
      } catch (error) {
        // console.error("Failed to fetch cards:", error);
      }
    };

    fetchCards();
  }, []);

  const handleDelete = async (cardId) => {
    const result = await Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    });

    if (result.isConfirmed) {
      try {
        await deleteCard(cardId);
        Swal.fire("Deleted!", "Your card has been deleted.", "success");
        setCards(cards.filter((card) => card.id !== cardId));
      } catch (error) {
        Swal.fire("Error!", "Failed to delete the card.", "error");
        console.error("Failed to delete card:", error);
      }
    }
  };

  return (
    <Box m="20px" height="80vh" overflow="auto">
      <Header title="HOME PAGE" subtitle="View all data in home page" />

      <Typography variant="h4" fontWeight="bold">
        Home Page Main Details
      </Typography>

      {frontDetails && frontDetails.result ? (
        <>
          <Button
            variant="contained"
            color="secondary"
            onClick={() => navigate("/home/editMainDetails")}
            sx={{ mb: 2, mt: 1 }}
          >
            Edit Main Topic Details
          </Button>
          <Accordion defaultExpanded>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography color={colors.greenAccent[500]} variant="h5">
                Front Detail Title
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>{frontDetails.result.title}</Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion defaultExpanded>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography color={colors.greenAccent[500]} variant="h5">
                Topic
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>{frontDetails.result.topic}</Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion defaultExpanded>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography color={colors.greenAccent[500]} variant="h5">
                Front Detail Description
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>{frontDetails.result.description}</Typography>
            </AccordionDetails>
          </Accordion>
        </>
      ) : (
        <>
          <Button
            variant="contained"
            color="secondary"
            onClick={() => navigate("/home/addMainDetails")}
            sx={{ mb: 2, mt: 1 }}
          >
            Add Main Topic Details
          </Button>
        </>
      )}

      <hr style={{ marginBottom: "20px" }}></hr>

      <Typography variant="h4" fontWeight="bold">
        Card Header Details
      </Typography>

      {isLoading ? (
        <Typography>Loading...</Typography>
      ) : companyDetails ? (
        <>
          <Button
            variant="contained"
            color="secondary"
            onClick={() => navigate("/home/editTopics")}
            sx={{ mb: 2, mt: 1 }}
          >
            Edit Card Header Details
          </Button>
          <Accordion defaultExpanded>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography color={colors.greenAccent[500]} variant="h5">
                Title
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>{companyDetails.title}</Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion defaultExpanded>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography color={colors.greenAccent[500]} variant="h5">
                Topic
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>{companyDetails.topic}</Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion defaultExpanded>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography color={colors.greenAccent[500]} variant="h5">
                Description
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>{companyDetails.description}</Typography>
            </AccordionDetails>
          </Accordion>
        </>
      ) : (
        <Button
          variant="contained"
          color="secondary"
          onClick={() => navigate("/home/addTopics")}
          sx={{ mb: 2 }}
        >
          Add Card Header Details
        </Button>
      )}

      <hr style={{ marginBottom: "20px" }} />
      <Typography variant="h4" fontWeight="bold" mt="20px" mb="10px">
        Card View
      </Typography>

      <Button
        variant="contained"
        color="secondary"
        onClick={() => navigate("/card/create")}
        sx={{ mb: 2 }}
      >
        Add New Card
      </Button>

      <Grid container spacing={2} mt="20px">
        {cards.map((card) => (
          <Grid item xs={12} md={4} key={card.id}>
            <Card>
              <CardMedia
                component="img"
                height="250px"
                width="auto"
                sx={{ objectFit: "cover" }}
                image={card.Image}
                alt={card.title}
              />
              <CardContent>
                <Typography variant="h6" gutterBottom>
                  Card Title: {card.title}
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  Card Description: {card.description}
                </Typography>
                <Box display="flex" justifyContent="flex-end" mt="10px">
                  <Tooltip title="View" arrow>
                    <IconButton
                      sx={{ color: colors.blueAccent[500], mx: 1 }}
                      onClick={() => navigate(`/card/view/${card.id}`)}
                    >
                      <VisibilityIcon />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="Edit" arrow>
                    <IconButton
                      sx={{ color: colors.blueAccent[500], mx: 1 }}
                      onClick={() => navigate(`/card/edit/${card.id}`)}
                    >
                      <EditIcon />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="Delete" arrow>
                    <IconButton
                      sx={{ color: colors.blueAccent[500], mx: 1 }}
                      onClick={() => handleDelete(card.id)}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </Tooltip>
                </Box>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default HOME;
