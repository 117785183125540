import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Box, Button, Typography, useTheme } from "@mui/material";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { getAboutUsById } from "../../../apiConfig/ApiConfig";
import Header from "../../../components/Header";
import { tokens } from "../../../theme";

const About = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [aboutUs, setAboutUs] = useState({
    title: "",
    topic: "",
    description: "",
    fb: "",
    twitter: "",
    instagram: "",
    linkedin: "",
    Image1: "",
    Image2: "",
  });
  const [dataExists, setDataExists] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchAboutUs = async () => {
      try {
        const response = await getAboutUsById(1);
        if (response.status === false && response.response_code === 404) {
          setDataExists(false);
        } else {
          const data = response.result;
          setAboutUs({
            title: data.title,
            topic: data.topic,
            description: data.description,
            fb: data.fb,
            twitter: data.twitter,
            instagram: data.instagram,
            linkedin: data.linkedin,
            Image1: data.Image1,
            Image2: data.Image2,
          });
          setDataExists(true);
        }
      } catch (error) {
        console.error("Failed to fetch About Us data:", error);
      }
    };

    fetchAboutUs();
  }, []);

  return (
    <Box m="20px" height="80vh" overflow="auto">
      <Header title="ABOUT US PAGE" subtitle="View all data in about-us page" />
      <Box>
        <Typography variant="h4" fontWeight="bold">
          All Details
        </Typography>

        <Button
          variant="contained"
          color="secondary"
          onClick={() =>
            navigate(dataExists ? `/aboutus/editAbout` : `/aboutus/addAbout`)
          }
          sx={{ mt: 1 }}
        >
          {dataExists ? "Edit About-Us Details" : "Add About-Us Details"}
        </Button>
      </Box>

      {dataExists && (
        <>
          <Accordion defaultExpanded>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography color={colors.greenAccent[500]} variant="h5">
                Title
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>{aboutUs.title}</Typography>
            </AccordionDetails>
          </Accordion>

          <Accordion defaultExpanded>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography color={colors.greenAccent[500]} variant="h5">
                Topic
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>{aboutUs.topic}</Typography>
            </AccordionDetails>
          </Accordion>

          <Accordion defaultExpanded>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography color={colors.greenAccent[500]} variant="h5">
                Description
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>{aboutUs.description}</Typography>
            </AccordionDetails>
          </Accordion>

          <Typography variant="h4" fontWeight="bold">
            Social Media Links
          </Typography>

          <Accordion defaultExpanded>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography color={colors.greenAccent[500]} variant="h5">
                Facebook
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>{aboutUs.fb}</Typography>
            </AccordionDetails>
          </Accordion>

          <Accordion defaultExpanded>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography color={colors.greenAccent[500]} variant="h5">
                TikTok
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>{aboutUs.twitter}</Typography>
            </AccordionDetails>
          </Accordion>

          <Accordion defaultExpanded>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography color={colors.greenAccent[500]} variant="h5">
                Instagram
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>{aboutUs.instagram}</Typography>
            </AccordionDetails>
          </Accordion>

          {/* <Accordion defaultExpanded>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography color={colors.greenAccent[500]} variant="h5">
                LinkedIn
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>{aboutUs.linkedin}</Typography>
            </AccordionDetails>
          </Accordion> */}

          <Typography variant="h4" fontWeight="bold">
            Images
          </Typography>

          {aboutUs.Image1 && (
            <Accordion defaultExpanded>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography color={colors.greenAccent[500]} variant="h5">
                  Image 1
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <img
                  src={aboutUs.Image1}
                  alt="Image 1"
                  style={{ width: "20%", height: "300px" }}
                />
              </AccordionDetails>
            </Accordion>
          )}

          {/* {aboutUs.Image2 && (
            <Accordion defaultExpanded>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography color={colors.greenAccent[500]} variant="h5">
                  Image 2
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <img
                  src={aboutUs.Image2}
                  alt="Image 2"
                  style={{ width: "20%", height: "300px" }}
                />
              </AccordionDetails>
            </Accordion>
          )} */}
        </>
      )}
    </Box>
  );
};

export default About;
