import PhotoCameraIcon from "@mui/icons-material/PhotoCamera";
import {
  Box,
  Button,
  CircularProgress,
  IconButton,
  Snackbar,
  TextField,
  Typography,
} from "@mui/material";
import MuiAlert from "@mui/material/Alert";
import useMediaQuery from "@mui/material/useMediaQuery";
import { ErrorMessage, Field, Form, Formik } from "formik";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import * as yup from "yup";
import { addCard } from "../../../../apiConfig/ApiConfig";
import Header from "../../../../components/Header";

const CreateCard = () => {
  const isNonMobile = useMediaQuery("(min-width:600px)");
  const navigate = useNavigate();
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [alertSeverity, setAlertSeverity] = useState("success");
  const [alertMessage, setAlertMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [imagePreview, setImagePreview] = useState(null);
  const [imageFile, setImageFile] = useState(null);

  const handleFormSubmit = async (values, { setErrors }) => {
    if (!imageFile) {
      setErrors({ imageFile: "Image is required" });
      return;
    }

    setLoading(true);
    const formData = new FormData();
    formData.append("title", values.title);
    formData.append("description", values.description);
    formData.append("image", imageFile);

    try {
      await addCard(formData);
      setAlertSeverity("success");
      setAlertMessage("Card added successfully!");
      setOpenSnackbar(true);
      setTimeout(() => {
        navigate("/home");
      }, 2500);
    } catch (error) {
      setAlertSeverity("error");
      setAlertMessage("Failed to add card.");
      setOpenSnackbar(true);
      console.error("Failed to add card:", error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Box m="20px">
      <Header title="CREATE NEW CARD" subtitle="Add new card" />

      <Formik
        onSubmit={handleFormSubmit}
        initialValues={initialValues}
        validationSchema={checkoutSchema}
      >
        {({
          values,
          errors,
          touched,
          handleBlur,
          handleChange,
          handleSubmit,
          setFieldValue,
          setErrors,
        }) => (
          <Form onSubmit={handleSubmit}>
            <Box
              display="grid"
              gap="30px"
              gridTemplateColumns="repeat(4, minmax(0, 1fr))"
              sx={{
                "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
              }}
            >
              <Field
                fullWidth
                variant="filled"
                type="text"
                label="Title"
                as={TextField}
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.title}
                name="title"
                error={!!touched.title && !!errors.title}
                helperText={touched.title && errors.title}
                sx={{ gridColumn: "span 4" }}
              />
              <Field
                fullWidth
                variant="filled"
                type="text"
                label="Description"
                as={TextField}
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.description}
                name="description"
                error={!!touched.description && !!errors.description}
                helperText={touched.description && errors.description}
                sx={{ gridColumn: "span 4" }}
              />
              <Box
                sx={{
                  gridColumn: "span 4",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "left",
                }}
              >
                <input
                  type="file"
                  accept="image/*"
                  id="image-upload"
                  style={{ display: "none" }}
                  onChange={(event) => {
                    const file = event.currentTarget.files[0];
                    setImageFile(file);
                    setImagePreview(URL.createObjectURL(file));
                    setFieldValue("imageFile", file); // Set the value in Formik
                  }}
                />
                <label htmlFor="image-upload">
                  <IconButton color="primary" component="span">
                    <Typography sx={{ marginRight: "20px" }}>
                      Upload Image
                    </Typography>
                    <PhotoCameraIcon />
                  </IconButton>
                </label>
                {imagePreview && (
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      mt: "10px",
                      width: "100px",
                      height: "100px",
                      border: "1px solid #ddd",
                      borderRadius: "4px",
                      overflow: "hidden",
                    }}
                  >
                    <img
                      src={imagePreview}
                      alt="Preview"
                      style={{
                        width: "100%",
                        height: "100%",
                        objectFit: "cover",
                      }}
                    />
                  </Box>
                )}
                <ErrorMessage
                  name="imageFile"
                  component="div"
                  style={{ color: "red" }}
                />
              </Box>
            </Box>
            <Box display="flex" justifyContent="end" mt="20px">
              <Button
                type="submit"
                color="secondary"
                variant="contained"
                disabled={loading}
                sx={{
                  bgcolor: loading ? "grey" : "secondary.main",
                  "&:hover": { bgcolor: loading ? "grey" : "secondary.dark" },
                  position: "relative",
                }}
              >
                {loading && (
                  <CircularProgress
                    size={24}
                    sx={{
                      position: "absolute",
                      left: "50%",
                      top: "50%",
                      marginLeft: "-12px",
                      marginTop: "-12px",
                    }}
                  />
                )}
                Create New Card
              </Button>
            </Box>
            <Snackbar
              open={openSnackbar}
              autoHideDuration={5000}
              onClose={() => setOpenSnackbar(false)}
              anchorOrigin={{ vertical: "top", horizontal: "right" }}
            >
              <MuiAlert
                onClose={() => setOpenSnackbar(false)}
                severity={alertSeverity}
                elevation={6}
                variant="filled"
                sx={{ color: "#fff" }}
              >
                {alertSeverity === "success" ? "Success" : "Error"}
                <br />
                {alertMessage}
              </MuiAlert>
            </Snackbar>
          </Form>
        )}
      </Formik>
    </Box>
  );
};

const checkoutSchema = yup.object().shape({
  title: yup.string().required("Title is required"),
  description: yup.string().required("Description is required"),
  imageFile: yup
    .mixed()
    .required("Image is required")
    .test(
      "fileSize",
      "File too large",
      (value) => !value || value.size <= 1024 * 1024 * 5
    ) // max 5MB
    .test(
      "fileFormat",
      "Unsupported Format",
      (value) =>
        !value || ["image/jpg", "image/jpeg", "image/png"].includes(value.type)
    ),
});

const initialValues = {
  title: "",
  description: "",
  imageFile: null,
};

export default CreateCard;
