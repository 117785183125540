import { Box, CardMedia, Typography, useTheme } from "@mui/material";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { getCardById } from "../../../../apiConfig/ApiConfig";
import Header from "../../../../components/Header";
import { tokens } from "../../../../theme";

const ViewCard = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const { id } = useParams();
  const [card, setCard] = useState(null);

  useEffect(() => {
    const fetchCardDetails = async () => {
      try {
        const cardData = await getCardById(id);
        setCard(cardData);
      } catch (error) {
        console.error("Failed to fetch card details:", error);
      }
    };

    fetchCardDetails();
  }, [id]);

  return (
    <Box m="20px">
      <Header title={`View Card ID: ${id}`} subtitle="View individual cards" />

      {card ? (
        <Box>
          {/* Card Title */}
          <Box mb={2}>
            <Typography variant="h4" color={colors.greenAccent[500]}>
              Title: {card.title}
            </Typography>
          </Box>
          {/* Card Image */}
          <Box mb={2}>
            <Typography variant="h6" gutterBottom>
              Image Preview
            </Typography>
            <CardMedia
              component="img"
              height="auto"
              width="200"
              image={card.Image}
              alt={card.title}
              sx={{ objectFit: "cover", width: "25%" }}
            />
          </Box>
          {/* Card Description */}
          <Box mb={2}>
            <Typography variant="body1">
              Description: {card.description}
            </Typography>
          </Box>
        </Box>
      ) : (
        <Typography variant="body1">Loading card details...</Typography>
      )}
    </Box>
  );
};

export default ViewCard;
