import PhotoCameraIcon from "@mui/icons-material/PhotoCamera";
import {
  Box,
  Button,
  CircularProgress,
  IconButton,
  Snackbar,
  TextField,
} from "@mui/material";
import MuiAlert from "@mui/material/Alert";
import useMediaQuery from "@mui/material/useMediaQuery";
import { Formik } from "formik";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import * as yup from "yup";
import {
  getAboutUsById,
  updateAboutUsById,
} from "../../../apiConfig/ApiConfig";
import Header from "../../../components/Header";

const EditAboutUs = () => {
  const isNonMobile = useMediaQuery("(min-width:600px)");

  const [image1, setImage1] = useState(null);
  const [image2, setImage2] = useState(null);
  const [image1Preview, setImage1Preview] = useState("");
  const [image2Preview, setImage2Preview] = useState("");
  const [loading, setLoading] = useState(false);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [alertSeverity, setAlertSeverity] = useState("success");
  const [alertMessage, setAlertMessage] = useState("");
  const [existingData, setExistingData] = useState(null);
  const [initialValues, setInitialValues] = useState({});
  const navigate = useNavigate();

  useEffect(() => {
    const fetchAboutUs = async () => {
      try {
        const response = await getAboutUsById(1);
        if (response.status) {
          setExistingData(response.result);
          setImage1Preview(response.result.Image1 || "");
          setImage2Preview(response.result.Image2 || "");
          setInitialValues({
            title: response.result.title || "",
            topic: response.result.topic || "",
            description: response.result.description || "",
            fb: response.result.fb || "",
            twitter: response.result.twitter || "",
            instagram: response.result.instagram || "",
            linkedin: response.result.linkedin || "",
            image1: null,
            // image2: null,
          });
        }
      } catch (error) {
        console.error("Failed to fetch About Us data:", error);
      }
    };

    fetchAboutUs();
  }, []);

  const hasChanges = (values) => {
    return (
      values.title !== existingData.title ||
      values.topic !== existingData.topic ||
      values.description !== existingData.description ||
      values.fb !== existingData.fb ||
      values.twitter !== existingData.twitter ||
      values.instagram !== existingData.instagram ||
      values.linkedin !== existingData.linkedin ||
      image1 !== null ||
      image2 !== null
    );
  };

  const handleFormSubmit = async (values, { setErrors }) => {
    if (!hasChanges(values)) {
      setAlertMessage("No changes detected.");
      setAlertSeverity("warning");
      setOpenSnackbar(true);
      return;
    }

    setLoading(true);

    try {
      const formData = new FormData();
      formData.append("title", values.title);
      formData.append("topic", values.topic);
      formData.append("description", values.description);
      formData.append("fb", values.fb);
      formData.append("twitter", values.twitter);
      formData.append("instagram", values.instagram);
      formData.append("linkedin", values.linkedin);
      if (image1) formData.append("image1", image1);
      // if (image2) formData.append("image2", image2);

      const response = await updateAboutUsById(1, formData);

      if (response.status) {
        setAlertSeverity("success");
        setAlertMessage(response.message);
        setOpenSnackbar(true);
        setTimeout(() => {
          navigate("/about");
        }, 2500);
      } else {
        setAlertSeverity("error");
        setAlertMessage("Failed to update About Us details.");
        setOpenSnackbar(true);
      }
    } catch (error) {
      console.error("Error updating About Us details:", error);
      setAlertSeverity("error");
      setAlertMessage("Failed to update About Us details.");
      setOpenSnackbar(true);
    } finally {
      setLoading(false);
    }
  };

  const validationSchema = yup.object().shape({
    title: yup.string().required("Title is Required"),
    topic: yup.string().required("Topic is Required"),
    description: yup.string().required("Description is Required"),
    fb: yup.string().required("Facebook URL is Required"),
    twitter: yup.string().required("Twitter URL is Required"),
    instagram: yup.string().required("Instagram URL is Required"),
    linkedin: yup.string().required("Linkedin URL is Required"),
    image1: yup
      .mixed()
      .test(
        "fileSize",
        "File too large",
        (value) => !value || value.size <= 1024 * 1024 * 5
      ) // max 5MB
      .test(
        "fileFormat",
        "Unsupported Format",
        (value) =>
          !value ||
          ["image/jpg", "image/jpeg", "image/png"].includes(value.type)
      ),
    // image2: yup
    //   .mixed()
    //   .test(
    //     "fileSize",
    //     "File too large",
    //     (value) => !value || value.size <= 1024 * 1024 * 5
    //   ) // max 5MB
    //   .test(
    //     "fileFormat",
    //     "Unsupported Format",
    //     (value) =>
    //       !value ||
    //       ["image/jpg", "image/jpeg", "image/png"].includes(value.type)
    //   ),
  });

  if (!existingData) return null;

  return (
    <Box m="20px" height="75vh" overflow="auto">
      <Header title="EDIT ABOUT US" subtitle="Edit existing about-us details" />
      <Formik
        onSubmit={handleFormSubmit}
        initialValues={initialValues}
        validationSchema={validationSchema}
      >
        {({
          values,
          errors,
          touched,
          handleBlur,
          handleChange,
          handleSubmit,
          setFieldValue,
        }) => (
          <form onSubmit={handleSubmit}>
            <Box
              display="grid"
              gap="20px"
              gridTemplateColumns="repeat(2, 1fr)"
              sx={{
                "& > div": { gridColumn: isNonMobile ? undefined : "span 2" },
              }}
            >
              <TextField
                label="Title"
                name="title"
                value={values.title}
                onChange={handleChange}
                onBlur={handleBlur}
                error={!!touched.title && !!errors.title}
                helperText={touched.title && errors.title}
                fullWidth
              />

              <TextField
                label="Topic"
                name="topic"
                value={values.topic}
                onChange={handleChange}
                onBlur={handleBlur}
                error={!!touched.topic && !!errors.topic}
                helperText={touched.topic && errors.topic}
                fullWidth
              />

              <TextField
                label="Description"
                name="description"
                value={values.description}
                onChange={handleChange}
                onBlur={handleBlur}
                error={!!touched.description && !!errors.description}
                helperText={touched.description && errors.description}
                fullWidth
              />

              <TextField
                label="Facebook URL"
                name="fb"
                value={values.fb}
                onChange={handleChange}
                onBlur={handleBlur}
                error={!!touched.fb && !!errors.fb}
                helperText={touched.fb && errors.fb}
                fullWidth
              />

              <TextField
                label="TikTok URL"
                name="twitter"
                value={values.twitter}
                onChange={handleChange}
                onBlur={handleBlur}
                error={!!touched.twitter && !!errors.twitter}
                helperText={touched.twitter && errors.twitter}
                fullWidth
              />

              <TextField
                label="Instagram URL"
                name="instagram"
                value={values.instagram}
                onChange={handleChange}
                onBlur={handleBlur}
                error={!!touched.instagram && !!errors.instagram}
                helperText={touched.instagram && errors.instagram}
                fullWidth
              />
              {/* 
              <TextField
                label="LinkedIn URL"
                name="linkedin"
                value={values.linkedin}
                onChange={handleChange}
                onBlur={handleBlur}
                error={!!touched.linkedin && !!errors.linkedin}
                helperText={touched.linkedin && errors.linkedin}
                fullWidth
              /> */}

              <Box gridColumn="span 2" mb={2}>
                <input
                  accept="image/*"
                  id="image1"
                  type="file"
                  style={{ display: "none" }}
                  onChange={(event) => {
                    const file = event.target.files[0];
                    setFieldValue("image1", file);
                    setImage1(file);
                    setImage1Preview(URL.createObjectURL(file));
                  }}
                />
                {image1Preview && (
                  <img
                    src={image1Preview}
                    alt="Preview 1"
                    style={{
                      width: "20%",
                      height: "auto",
                      maxHeight: "200px",
                      border: "1px solid #000",
                    }}
                  />
                )}
                <label htmlFor="image1">
                  <IconButton
                    color="primary"
                    aria-label="upload picture"
                    component="span"
                  >
                    Change Image
                    <PhotoCameraIcon />
                  </IconButton>
                </label>
              </Box>

              {/* <Box gridColumn="span 2" mb={2}>
                <input
                  accept="image/*"
                  id="image2"
                  type="file"
                  style={{ display: "none" }}
                  onChange={(event) => {
                    const file = event.target.files[0];
                    setFieldValue("image2", file);
                    setImage2(file);
                    setImage2Preview(URL.createObjectURL(file));
                  }}
                />
                {image2Preview && (
                  <img
                    src={image2Preview}
                    alt="Preview 2"
                    style={{
                      width: "20%",
                      height: "auto",
                      maxHeight: "200px",
                      border: "1px solid #000",
                    }}
                  />
                )}
                <label htmlFor="image2">
                  <IconButton
                    color="primary"
                    aria-label="upload picture"
                    component="span"
                  >
                    Change Image
                    <PhotoCameraIcon />
                  </IconButton>
                </label>
              </Box> */}
            </Box>

            <Box display="flex" justifyContent="end" m="20px">
              <Button
                type="submit"
                color="secondary"
                variant="contained"
                disabled={loading}
                sx={{
                  bgcolor: loading ? "grey" : "secondary.main",
                  "&:hover": { bgcolor: loading ? "grey" : "secondary.dark" },
                  position: "relative",
                }}
              >
                {loading && (
                  <CircularProgress
                    size={24}
                    sx={{
                      position: "absolute",
                      left: "50%",
                      top: "50%",
                      marginLeft: "-12px",
                      marginTop: "-12px",
                    }}
                  />
                )}
                Update About Us Details
              </Button>
            </Box>
          </form>
        )}
      </Formik>

      <Snackbar
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={() => setOpenSnackbar(false)}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <MuiAlert
          elevation={6}
          variant="filled"
          onClose={() => setOpenSnackbar(false)}
          severity={alertSeverity}
        >
          {alertMessage}
        </MuiAlert>
      </Snackbar>
    </Box>
  );
};

export default EditAboutUs;
