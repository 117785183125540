import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Box, Button, Typography, useTheme } from "@mui/material";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { getContactUsData } from "../../../apiConfig/ApiConfig";
import Header from "../../../components/Header";
import { tokens } from "../../../theme";

const Contact = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const navigate = useNavigate();
  const [contactData, setContactData] = useState({
    location: "",
    email: "",
    phoneNumber: "",
  });
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await getContactUsData();

        if (data.status) {
          setContactData(data.result);
        } else {
          setError(data.message);
        }
      } catch (error) {
        setError("An error occurred while fetching contact details.");
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, []);

  if (loading) return <Typography>Loading...</Typography>;

  if (error) {
    return (
      <Box m="20px" height="80vh" overflow="auto">
        <Header
          title="CONTACT US"
          subtitle="View all data in contact us page"
        />

        <Button
          variant="contained"
          color="secondary"
          onClick={() => navigate("/contact/addContact")}
          sx={{ mt: 2 }}
        >
          Add Contact Details
        </Button>
      </Box>
    );
  }

  return (
    <Box m="20px" height="80vh" overflow="auto">
      <Header title="CONTACT US" subtitle="View all data in contact us page" />

      <Button
        variant="contained"
        color="secondary"
        onClick={() => navigate("/contact/editContact")}
        sx={{ mb: 2, mt: 1 }}
      >
        Edit Contact Details
      </Button>
      <Accordion defaultExpanded>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography color={colors.greenAccent[500]} variant="h5">
            Location
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            {contactData.location || "No location provided"}
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion defaultExpanded>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography color={colors.greenAccent[500]} variant="h5">
            Email
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>{contactData.email || "No email provided"}</Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion defaultExpanded>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography color={colors.greenAccent[500]} variant="h5">
            Phone Number
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            {contactData.phoneNumber || "No phone number provided"}
          </Typography>
        </AccordionDetails>
      </Accordion>
    </Box>
  );
};

export default Contact;
