import PhotoCameraIcon from "@mui/icons-material/PhotoCamera";
import {
  Box,
  Button,
  CircularProgress,
  IconButton,
  Snackbar,
  TextField,
  Typography,
} from "@mui/material";
import MuiAlert from "@mui/material/Alert";
import useMediaQuery from "@mui/material/useMediaQuery";
import { ErrorMessage, Field, Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import * as yup from "yup";
import { getCardById, updateCard } from "../../../../apiConfig/ApiConfig";
import Header from "../../../../components/Header";

const EditCard = () => {
  const isNonMobile = useMediaQuery("(min-width:600px)");
  const navigate = useNavigate();
  const { id } = useParams();
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [alertSeverity, setAlertSeverity] = useState("success");
  const [alertMessage, setAlertMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [imagePreview, setImagePreview] = useState(null);
  const [imageFile, setImageFile] = useState(null);
  const [initialValues, setInitialValues] = useState({
    title: "",
    description: "",
    imageFile: null,
  });
  const [isLoadingData, setIsLoadingData] = useState(true);

  useEffect(() => {
    const fetchCardDetails = async () => {
      try {
        const result = await getCardById(id);

        if (result) {
          console.log("Fetched data:", result);
          setInitialValues({
            title: result.title,
            description: result.description,
            imageFile: null,
          });
          setImagePreview(result.Image || null);
        } else {
          console.error("Card data is not available in the response.");
          setAlertSeverity("error");
          setAlertMessage("Failed to fetch card details.");
          setOpenSnackbar(true);
        }
      } catch (error) {
        console.error("Failed to fetch card details:", error);
        setAlertSeverity("error");
        setAlertMessage("Failed to fetch card details.");
        setOpenSnackbar(true);
      } finally {
        setIsLoadingData(false);
      }
    };

    fetchCardDetails();
  }, [id]);

  const handleFormSubmit = async (values, { setErrors }) => {
    if (
      values.title === initialValues.title &&
      values.description === initialValues.description &&
      !imageFile
    ) {
      setAlertSeverity("warning");
      setAlertMessage("No changes detected.");
      setOpenSnackbar(true);
      return;
    }

    setLoading(true);
    const formData = new FormData();
    formData.append("title", values.title);
    formData.append("description", values.description);
    if (imageFile) {
      formData.append("image", imageFile);
    }

    try {
      await updateCard(id, formData);
      setAlertSeverity("success");
      setAlertMessage("Card updated successfully!");
      setOpenSnackbar(true);
      setTimeout(() => {
        navigate("/home");
      }, 2500);
    } catch (error) {
      setAlertSeverity("error");
      setAlertMessage("Failed to update card.");
      setOpenSnackbar(true);
      console.error("Failed to update card:", error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Box m="20px">
      <Header title="EDIT CARD" subtitle="Update card details" />

      {isLoadingData ? (
        <CircularProgress />
      ) : (
        <Formik
          onSubmit={handleFormSubmit}
          initialValues={initialValues}
          validationSchema={checkoutSchema}
        >
          {({
            values,
            errors,
            touched,
            handleBlur,
            handleChange,
            handleSubmit,
            setFieldValue,
          }) => (
            <Form onSubmit={handleSubmit}>
              <Box
                display="grid"
                gap="30px"
                gridTemplateColumns="repeat(4, minmax(0, 1fr))"
                sx={{
                  "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
                }}
              >
                <Field
                  fullWidth
                  variant="filled"
                  type="text"
                  label="Title"
                  as={TextField}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.title}
                  name="title"
                  error={!!touched.title && !!errors.title}
                  helperText={touched.title && errors.title}
                  sx={{ gridColumn: "span 4" }}
                />
                <Field
                  fullWidth
                  variant="filled"
                  type="text"
                  label="Description"
                  as={TextField}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.description}
                  name="description"
                  error={!!touched.description && !!errors.description}
                  helperText={touched.description && errors.description}
                  sx={{ gridColumn: "span 4" }}
                />
                <Box
                  sx={{
                    gridColumn: "span 4",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "left",
                  }}
                >
                  <input
                    type="file"
                    accept="image/*"
                    id="image-upload"
                    style={{ display: "none" }}
                    onChange={(event) => {
                      const file = event.currentTarget.files[0];
                      setImageFile(file);
                      setImagePreview(URL.createObjectURL(file));
                      setFieldValue("imageFile", file);
                    }}
                  />
                  <label htmlFor="image-upload">
                    <IconButton color="primary" component="span">
                      <Typography sx={{ marginRight: "20px" }}>
                        Upload Image
                      </Typography>
                      <PhotoCameraIcon />
                    </IconButton>
                  </label>
                  {imagePreview && (
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        mt: "10px",
                        width: "200px",
                        height: "200px",
                        border: "1px solid #ddd",
                        borderRadius: "4px",
                        overflow: "hidden",
                      }}
                    >
                      <img
                        src={imagePreview}
                        alt="Preview"
                        style={{
                          width: "100%",
                          height: "100%",
                          objectFit: "cover",
                        }}
                      />
                    </Box>
                  )}
                  <ErrorMessage
                    name="imageFile"
                    component="div"
                    style={{ color: "red" }}
                  />
                </Box>
              </Box>
              <Box display="flex" justifyContent="end" mt="20px">
                <Button
                  type="submit"
                  color="secondary"
                  variant="contained"
                  disabled={loading}
                  sx={{
                    bgcolor: loading ? "grey" : "secondary.main",
                    "&:hover": { bgcolor: loading ? "grey" : "secondary.dark" },
                    position: "relative",
                  }}
                >
                  {loading && (
                    <CircularProgress
                      size={24}
                      sx={{
                        position: "absolute",
                        left: "50%",
                        top: "50%",
                        marginLeft: "-12px",
                        marginTop: "-12px",
                      }}
                    />
                  )}
                  Update Card
                </Button>
              </Box>
              <Snackbar
                open={openSnackbar}
                autoHideDuration={5000}
                onClose={() => setOpenSnackbar(false)}
                anchorOrigin={{ vertical: "top", horizontal: "right" }}
              >
                <MuiAlert
                  onClose={() => setOpenSnackbar(false)}
                  severity={alertSeverity}
                  elevation={6}
                  variant="filled"
                  sx={{ color: "#fff" }}
                >
                  {/* {alertSeverity === "success" ? "Success" : "Error"} */}
                  {/* <br /> */}
                  {alertMessage}
                </MuiAlert>
              </Snackbar>
            </Form>
          )}
        </Formik>
      )}
    </Box>
  );
};

const checkoutSchema = yup.object().shape({
  title: yup.string().required("Title is required"),
  description: yup.string().required("Description is required"),
  imageFile: yup
    .mixed()
    .test(
      "fileSize",
      "File too large",
      (value) => !value || value.size <= 1024 * 1024 * 5
    )
    .test(
      "fileFormat",
      "Unsupported Format",
      (value) =>
        !value || ["image/jpg", "image/jpeg", "image/png"].includes(value.type)
    ),
});

export default EditCard;
