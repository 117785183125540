import { Box, Button, Snackbar, TextField } from "@mui/material";
import MuiAlert from "@mui/material/Alert";
import useMediaQuery from "@mui/material/useMediaQuery";
import { Field, Form, Formik } from "formik";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import * as yup from "yup";
import {
  getFrontDetails,
  updateMainTopicDetails,
} from "../../../apiConfig/ApiConfig";
import Header from "../../../components/Header";

const EditMainTopics = () => {
  const isNonMobile = useMediaQuery("(min-width:600px)");
  const [initialValues, setInitialValues] = useState({
    title: "",
    topic: "",
    description: "",
  });
  const [currentValues, setCurrentValues] = useState({
    title: "",
    topic: "",
    description: "",
  });
  const [loading, setLoading] = useState(false);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [alertSeverity, setAlertSeverity] = useState("success");
  const [alertMessage, setAlertMessage] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    const fetchCompanyDetails = async () => {
      try {
        const details = await getFrontDetails(1);

        setInitialValues(details.result);
        setCurrentValues(details.result);
      } catch (error) {
        console.error("Failed to fetch card header details:", error);
        setAlertSeverity("error");
        setAlertMessage("Failed to fetch card header details.");
        setOpenSnackbar(true);
      }
    };

    fetchCompanyDetails();
  }, []);

  const hasChanges = (values) => {
    return (
      values.title !== initialValues.title ||
      values.topic !== initialValues.topic ||
      values.description !== initialValues.description
    );
  };

  const handleFormSubmit = async (values) => {
    if (!hasChanges(values)) {
      setAlertSeverity("warning");
      setAlertMessage("No changes detected.");
      setOpenSnackbar(true);
      return;
    }

    setLoading(true);
    try {
      await updateMainTopicDetails(1, values);
      setAlertSeverity("success");
      setAlertMessage("Main topic details updated successfully!");
      setOpenSnackbar(true);
      setTimeout(() => {
        navigate("/home");
      }, 2500);
    } catch (error) {
      setAlertSeverity("error");
      setAlertMessage("Failed to main topic details.");
      setOpenSnackbar(true);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Box m="20px">
      <Header
        title="EDIT MAIN TOPIC"
        subtitle="Edit Existing Main Header Details"
      />

      <Formik
        onSubmit={handleFormSubmit}
        initialValues={currentValues}
        validationSchema={checkoutSchema}
        enableReinitialize
      >
        {({
          values,
          errors,
          touched,
          handleBlur,
          handleChange,
          handleSubmit,
        }) => (
          <Form onSubmit={handleSubmit}>
            <Box
              display="grid"
              gap="30px"
              gridTemplateColumns="repeat(4, minmax(0, 1fr))"
              sx={{
                "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
              }}
            >
              <Field
                fullWidth
                variant="filled"
                type="text"
                label="Title"
                as={TextField}
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.title}
                name="title"
                error={!!touched.title && !!errors.title}
                helperText={touched.title && errors.title}
                sx={{ gridColumn: "span 4" }}
              />
              <Field
                fullWidth
                variant="filled"
                type="text"
                label="Topic"
                as={TextField}
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.topic}
                name="topic"
                error={!!touched.topic && !!errors.topic}
                helperText={touched.topic && errors.topic}
                sx={{ gridColumn: "span 4" }}
              />
              <Field
                fullWidth
                variant="filled"
                type="text"
                label="Description"
                as={TextField}
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.description}
                name="description"
                error={!!touched.description && !!errors.description}
                helperText={touched.description && errors.description}
                sx={{ gridColumn: "span 4" }}
              />
            </Box>
            <Box display="flex" justifyContent="end" mt="20px">
              <Button
                type="submit"
                color="secondary"
                variant="contained"
                disabled={loading}
                sx={{
                  bgcolor: loading ? "grey" : "secondary.main",
                  "&:hover": { bgcolor: loading ? "grey" : "secondary.dark" },
                }}
              >
                {loading ? "Updating..." : "Update Details"}
              </Button>
            </Box>
          </Form>
        )}
      </Formik>

      <Snackbar
        open={openSnackbar}
        autoHideDuration={5000}
        onClose={() => setOpenSnackbar(false)}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <MuiAlert
          onClose={() => setOpenSnackbar(false)}
          severity={alertSeverity}
          elevation={6}
          variant="filled"
          sx={{ color: "#fff" }}
        >
          {alertMessage}
        </MuiAlert>
      </Snackbar>
    </Box>
  );
};

const checkoutSchema = yup.object().shape({
  title: yup.string().required("Title is required"),
  topic: yup.string().required("Topic is required"),
  description: yup.string().required("Description is required"),
});

export default EditMainTopics;
